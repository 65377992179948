/* ----global css start----------- */
.icon {
  font-size: 18px;
}
.long_arrow {
  font-weight: 600;
  font-size: 20px;
}
/* ----global css end----------- */

.img_projects {
  width: 320px;
  height: 320px;
  padding: 10px;
  object-fit: cover;
}

/* Normal desktop :992px. */
@media (max-width: 700px) {
  .img_projects {
    width: 95%;
    height: 320px;
    padding: 10px;
    object-fit: cover;
  }
}

/* video promocional */
.bgCountentVideo {
  position: relative;
  padding: 100px 0 0;
  margin-bottom: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-bottom-left-radius: 100% 80%;
  border-bottom-right-radius: 100% 80%;
}

.bgCountentVideo .info {
  width: 60%;
  margin: 0 auto -80px;
}

.bgCountentVideo .countentVideoInfo {
  position: relative;
  text-align: center;
  color: rgba(0, 0, 0, 0.397);
}

.bgCountent::before,
.bgFormHome::before,
.bgCountentVideo::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.bgCountentVideo::before {
  border-bottom-left-radius: 100% 80%;
  border-bottom-right-radius: 100% 80%;
  background-color: var(--overlay-video-content);
}

.bgCountent::before,
.bgFormHome::before {
  background-color: rgba(0, 0, 0, 0.6);
}



@media screen and (max-width:569px) {
  .bgCountentVideo {
    margin-bottom: 100px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bgCountentVideo::before {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bgCountentVideo .info {
    width: 90%;
    margin: 0 auto -30px;
  }
}

.promotional_video{
  width: 90%;
  height: 280px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1.5rem/* 24px */;
  position: relative;
  top: 80px;
}

@media (min-width: 768px) {
  .promotional_video{
    height: 360px;
    width: 50%;
  }
}

.bgCountentVideo {
  position: relative;
  padding: 100px 0 0;
  margin-bottom: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-bottom-left-radius: 100% 80%;
  border-bottom-right-radius: 100% 80%;
}

.bgCountentVideo .info {
  width: 60%;
  margin: 0 auto -80px;
}

.bgCountentVideo .countentVideoInfo {
  position: relative;
  text-align: center;
  color: rgba(0, 0, 0, 0.363);
}

.bgCountent::before,
.bgFormHome::before,
.bgCountentVideo::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.bgCountentVideo::before {
  border-bottom-left-radius: 100% 80%;
  border-bottom-right-radius: 100% 80%;
  background-color: var(--overlay-video-content);
}

.bgCountent::before,
.bgFormHome::before {
  background-color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width:569px) {
  .bgCountentVideo {
    margin-bottom: 100px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bgCountentVideo::before {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bgCountentVideo .info {
    width: 90%;
    margin: 0 auto -30px;
  }
}

.promotional_video{
  width: 90%;
  height: 280px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1.5rem/* 24px */;
  position: relative;
  top: 80px;
}

@media (min-width: 768px) {
  .promotional_video{
    height: 360px;
    width: 50%;
  }
}

.secgoogle {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
}

@media screen and (max-width: 768px) {
  .secgoogle {
    display: block;
    position: relative;

  }
  
}

